<template>
  <div class="upload">
    <div class="flex xs12">
      <va-card title="请上传图像">
        <div class="flex md12 sm12 xs12">
          <va-input v-model="qrcode" placeholder="对应照片编号" />
        </div>

        <!-- <div class="flex md6 sm6 xs6"> -->
        <va-file-upload type="single" v-model="filelist"
          >选择图像</va-file-upload
        >
        <!-- </div>
        <div class="flex md6 sm6 xs6">-->
        <va-button big @click="onSubmit">提交</va-button>
        <!-- </div> -->
      </va-card>
    </div>
  </div>
</template>

<script>
// import { compress, compressAccurately } from "image-conversion";
import * as imageConversion from "image-conversion";
export default {
  name: "file-upload",
  data() {
    return {
      b1: "select file",
      b2: "upload file",
      filelist: [],
      qrcode: "",
      qrcodeErrors: [],
      fileErrors: [],
      toastText: "",
      toastDuration: 2500,
      toastPosition: "top-center",
      isToastFullWidth: false,
    };
  },
  computed: {
    formReady() {
      // console.log(this.fileErrors.length)
      return !this.qrcodeErrors.length && !this.fileErrors.length;
    },
  },
  methods: {
    onSubmit() {
      this.qrcodeErrors = this.qrcode ? [] : ["qrcode is required"];
      if (this.filelist.length == 0) {
        this.fileErrors = ["picture file is required"];
      } else {
        this.fileErrors = [];
      }
      //   this.fileErrors = this.filelist ? [] : ["picture file is required"];
      if (this.formReady) {
        var curr = this.filelist.length - 1;
        var file = this.filelist[curr];

        //   console.log(this.filelist);
        //   console.log(file);
        //   console.log(file.size);

        imageConversion.compressAccurately(file, 1024).then((res) => {
          //The res in the promise is a compressed Blob type (which can be treated as a File type) file;
          // console.log("file and file size after compress");
          // console.log(res);
          // console.log(res.size);
          var pic = imageConversion.filetoDataURL(res).then((res) => {
            this.axios
              .post(
                "/v1/picture/upload",
                {
                  qrcode: this.qrcode,
                  picture: res,
                  access_token: sessionStorage["access_token"],
                },
                {
                  headers: {
                    Authorization: "Bearer " + sessionStorage["access_token"],
                  },
                }
              )
              .then((res) => {
                console.log(res);
                if (res.status === 200) {
                  this.toastText = "上传成功";
                  this.showToast(this.toastText, {
                    position: this.toastPosition,
                    duration: this.toastDuration,
                    fullWidth: this.isToastFullWidth,
                  });
                }
              })
              .catch((error) => {
                console.log(error);
                if (error.response.status === 409) {
                  this.toastText = "该编号已绑定过其他照片";
                  this.showToast(this.toastText, {
                    position: this.toastPosition,
                    duration: this.toastDuration,
                    fullWidth: this.isToastFullWidth,
                  });
                }
              });
          });
          // console.log("to url");
          //   console.log(pic);
        });
      } else {
        this.toastText = "请检查编号以及文件是否存在";
        this.showToast(this.toastText, {
          position: this.toastPosition,
          duration: this.toastDuration,
          fullWidth: this.isToastFullWidth,
        });
      }
    },

    //   if (error.response.status === 401) {
    //     this.toastText = "请检查账号密码是否正确";
    //     this.showToast(this.toastText, {
    //       position: this.toastPosition,
    //       duration: this.toastDuration,
    //       fullWidth: this.isToastFullWidth,
    //     });
    //   }
  },
};
</script>
